document.addEventListener('DOMContentLoaded', function () {
    const headerMenu = document.querySelector('header ul#menu-main-menu');
    if (!headerMenu) return;

    const headerMenuItems = document.querySelectorAll('header ul#menu-main-menu > li.menu-item');
    const headerMenuActiveItem = headerMenu.querySelector('li.current-menu-item');
    const headerMenuUnderline = headerMenu.querySelector('.underline');
    if (!headerMenuActiveItem || !headerMenuUnderline) return;

    let cachedLeftOffset = headerMenuActiveItem.offsetLeft;
    let cachedOffsetWidth = headerMenuActiveItem.offsetWidth;

    function setActiveUnderline(item) {
        const targetItem = item || headerMenuActiveItem;
        cachedLeftOffset = targetItem.offsetLeft;
        cachedOffsetWidth = targetItem.offsetWidth;
        headerMenuUnderline.style.transform = `translateX(${cachedLeftOffset}px)`;
        headerMenuUnderline.style.width = `${cachedOffsetWidth}px`;
    }

    window.addEventListener('load', () => {
        setActiveUnderline();
        requestAnimationFrame(() => {
            headerMenuUnderline.style.transition = '0.3s transform ease-in-out, 0.3s width ease-in-out';
        });
    });

    let resizeTimeout;
    window.addEventListener('resize', () => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
            setActiveUnderline();
        }, 200);
    });

    headerMenuItems.forEach(item => {
        item.addEventListener('mouseover', () => {
            setActiveUnderline(item);
        });

        item.addEventListener('mouseleave', () => {
            requestAnimationFrame(() => setActiveUnderline());
        });
    });
});