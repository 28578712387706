class MultiStepForm {
    constructor(form) {
      if (typeof form === 'string') {
      this.form = document.querySelector(form);
      } else if (form instanceof HTMLElement) {
      this.form = form;
      } else {
      throw new Error('Invalid form selector or instance');
      }
      this.pages = this.form.querySelectorAll('.page');
      this.currentPage = 0;
      this.init();
    }
  
    init() {
      this.showPage(this.currentPage);
      this.form.addEventListener('click', (e) => this.handleButtonClick(e));
      this.form.addEventListener('submit', (e) => this.handleSubmit(e));
    }
  
    showPage(index) {
      this.pages.forEach((page, i) => {
        page.classList.toggle('active', i === index);
      });

      // Update the current page number
      this.currentPage = index;
    }
  
    handleButtonClick(event) {
      if (event.target.matches('.next-step')) {
        event.preventDefault();
        if (this.validatePage(this.currentPage)) {
          this.currentPage++;
          this.showPage(this.currentPage);
        }
      } else if (event.target.matches('.prev-step')) {
        event.preventDefault();
        this.currentPage--;
        this.showPage(this.currentPage);
      }
    }
  
    validatePage(index) {
      const inputs = this.pages[index].querySelectorAll('input, select, textarea');
      let allValid = true;
      for (let input of inputs) {
      if (!input.checkValidity()) {
        input.reportValidity();
        input.classList.add('invalid');
        allValid = false;
      } else {
        input.classList.remove('invalid');
      }
      }
      return allValid;
    }
  
    handleSubmit(event) {
      if (!this.validatePage(this.currentPage)) {
        event.preventDefault();
      }
    }
  }
  
  // Initialize the multistep form
  document.addEventListener('DOMContentLoaded', () => {
   
    const msfForms = document.querySelectorAll('.msf');
    msfForms.forEach((form) => {
      const msf = new MultiStepForm(form);
      form.msf = msf;
    });
  });
  