document.addEventListener('DOMContentLoaded', function () {
    const lazyVideos = document.querySelectorAll('video[data-video-src]');

    if (!lazyVideos.length) {
        return;
    }

    lazyVideos.forEach(video => {
        const videoSource = video.querySelector('source');
        videoSource.src = video.getAttribute('data-video-src');
        // remove the data-src attribute to prevent loading the video multiple times
        video.removeAttribute('data-video-src');

        video.load();
    });
});