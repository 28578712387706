document.addEventListener('devkitLazyLoad-Wave', () => {
    // Initialize the canvas animation for all canvases with class 'waveCanvas'
    document.querySelectorAll('.applicationHeroCanvas').forEach(canvas => {
        new window.WaveCanvas(canvas, {
            widthOffset: 0,
            verticalOffset: -1000,
            diagonalSlopeFactor: 0.7,
            horizontalOffset: 0,
            baseAmplitude: 60,
        });
    });
});

