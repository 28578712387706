/**
 * Event listener for 'devkitLazyLoad-swiper' that initializes Swiper instances for review sliders.
 *
 * This function selects all elements with the class 'applications-slider' and initializes a Swiper instance
 * for each element that contains at least two slides. 
 *
 * @event devkitLazyLoad-swiper
 */
document.addEventListener('devkitLazyLoad-Swiper', function () {

    const applicationsSliders = document.querySelectorAll('.applications-slider');

    applicationsSliders.forEach(function (slider) {

        const sliderContainer = slider.closest('.application-slider');


        /**
         * Initializes a Swiper instance.
         *
         * @param {HTMLElement} slider - The DOM element that contains the Swiper slider.
         * @returns {Swiper} The initialized Swiper instance.
         *
         */
    new Swiper(slider, {
        slidesPerView: 1.2,
        // Draggable
        draggable: true,
        loop: false,
        spaceBetween: 20,
        navigation: {
        nextEl: sliderContainer.querySelector('.swiper-button-next'),
        prevEl: sliderContainer.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
        992: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        },
    });
    });

})