document.addEventListener('DOMContentLoaded', function() {
    const belowFoldEls = document.querySelectorAll('.below-fold');

    if (!belowFoldEls) {
        return;
    }

    let observerOptions = {
      
    };

    if (window.innerWidth < 992) {
        observerOptions = {
            threshold: 0 // Adjust this value as needed
        };
    }

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target);
            }
        });
    }, observerOptions);

    belowFoldEls.forEach(el => {
        observer.observe(el);
    });

});

