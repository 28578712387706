document.addEventListener("DOMContentLoaded", function () {
    const requirementsForm = document.querySelector('.requirements-form form');

    if (!requirementsForm) {
        return;
    }

    const pages = requirementsForm.querySelectorAll('.page');
    const msg = requirementsForm.querySelector('.msg');

    if (!requirementsForm) {
        return;
    }

    requirementsForm.addEventListener('submit', function (e) {
        e.preventDefault();
        devkitFormSubmit(this, 'validate_product_cta_form').then((json) => {
            if (json.success) {
                if (this.dataset.redirect) {
                    window.location.href = this.dataset.redirect;
                } else {
                    this.innerHTML = "<h3>Your submission was successful.</h3>";
                }
            } else {
                this.classList.add('error');

                const fields_with_errors = json.data.invalid_fields;

                fields_with_errors.forEach((field) => {
                   const input = this.querySelector(`[name="${field}"]`);
                   input.classList.add('invalid');
               });

               // Display page with first error
               const firstError = fields_with_errors[0];
      

               const input = this.querySelector(`[name="${firstError}"]`);
               const page = input.closest('.page');
               const pageIndex = Array.from(pages).indexOf(page);


               this.msf.showPage(pageIndex);

                msg.innerHTML = json.data.message;
            }
        });
    });
}
);

