document.addEventListener('DOMContentLoaded', function () {
    // Select all .product-row elements
    const productRows = document.querySelectorAll('.product-row');

    if (!productRows) {
        return;
    }

    // Define the observer options
    const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px', // Start observing as soon as it appears in the viewport
        threshold: 1 // Element should be at least 10% visible to trigger
    };

    // Create a new IntersectionObserver
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                // Stop observing once the class is added, if you only want it to fire once
            } else {
                entry.target.classList.remove('visible');
            }
        });
    }, observerOptions);

    // Observe each product row
    productRows.forEach(row => observer.observe(row));

 
    const container = document.querySelector('.product-parts-scroll-slide');
    if (!container) {
        return;
    }
    
    const heading = document.querySelectorAll('.product-parts-scroll-slide h2')[0];

    window.addEventListener("scroll", () => {
        if (window.innerWidth < 992) {
            const scrollPosition = window.scrollY;

            // Adjust the scroll range for the movement
            const movementStart = (container.offsetTop - window.innerHeight) + 400; // Start before the container is in view
            const movementEnd = container.offsetTop + container.offsetHeight; // End after the container scrolls past
            const totalScrollDistance = movementEnd - movementStart;

            // Calculate how much the user has scrolled relative to the movement start
            const scrolled = scrollPosition - movementStart;

            // Introduce a movement duration factor
            const movementDuration = .7; // Increase to make the movement last longer

            // Calculate the progress of the movement
            const progress = scrolled / (totalScrollDistance * movementDuration);

            // Clamp the progress between 0 and 1
            const clampedProgress = Math.min(Math.max(progress, 0), 1);

            // Optional: Apply an easing function
            function easeOutQuad(x) {
                return 1 - (1 - x) * (1 - x);
            }

            const easedProgress = easeOutQuad(clampedProgress);

            // Calculate the translate value
            const translateValue = easedProgress * 100; // From 0% to 100%

            // Apply the transformation
            heading.style.transform = `translateX(calc(-100% + ${translateValue}%)) translateY(-50%)`;
        } else {
            heading.style.transform = '';
        }
    });



});