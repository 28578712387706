document.addEventListener("DOMContentLoaded", function() {
    const logos = document.querySelectorAll(".logos div");

    function fadeInLogos() {
        const logosContainer = document.querySelector('.logos');
        if (logosContainer) {
            const windowHeight = window.innerHeight;
            const distanceFromTop = logosContainer.getBoundingClientRect().top;

            if (distanceFromTop < windowHeight - 100) {
                logos.forEach((logo, index) => {
                    setTimeout(() => {
                        logo.classList.add('visible');
                    }, index * 200); // Delay each logo's fade-in
                });
                window.removeEventListener('scroll', fadeInLogos); // Remove event after animation
            }
        }
    }

    window.addEventListener('scroll', fadeInLogos);
});
