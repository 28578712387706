document.addEventListener("DOMContentLoaded", function () {


    const contactWaveCanvas = document.querySelector('.contactWaveCanvas');

    const contactForm = document.querySelector('section.contact-form form');

    if (!contactForm) {
        return;
    }

    contactForm.addEventListener('submit', function (e) {
        e.preventDefault();
        devkitFormSubmit(this, 'validate_product_cta_form').then((json) => {
            if (this.dataset.redirect) {
                window.location.href = this.dataset.redirect;
            } else {
                this.innerHTML = "<h3>Your submission was successful.</h3>";
            }
        });
    });


    const textAreaMessage = contactForm.querySelector('textarea');


    const characterLimitEl = textAreaMessage.parentNode.appendChild(createCharacterLimitEl());


    function createCharacterLimitEl() {
        const characterLimitEl = document.createElement('div');
        characterLimitEl.classList.add('character-limit');
        characterLimitEl.classList.add('mt-1');
        characterLimitEl.innerHTML = `<span class="total">0</span> / 300 <span class="reached d-none">- You have reached the character limit.</span>`;

        return characterLimitEl;
    }

 

    textAreaMessage.addEventListener('input', function () {
        const charCount = this.value.length;
        characterLimitEl.querySelector('span.total').textContent = charCount;


        if (charCount >= 300) {
            characterLimitEl.classList.add('over-limit');
            characterLimitEl.querySelector('.reached').classList.remove('d-none');
        } else {
            characterLimitEl.classList.remove('over-limit');
            characterLimitEl.querySelector('.reached').classList.add('d-none');
        }
    });
  
});


document.addEventListener("devkitLazyLoad-Wave", function () {


    const contactWaveCanvas = document.querySelector('.contactWaveCanvas');

    if (contactWaveCanvas) {
        new WaveCanvas(contactWaveCanvas, {
            widthOffset: 0,
            verticalOffset: -350,
            diagonalSlopeFactor: 0.15,
            frequency: 0.005,
        });
    }


});
