document.addEventListener("DOMContentLoaded", function () {

    const specModal = document.querySelector(".product-full-specification");

    const languageModal = document.querySelector(".language-picker-modal");

    // Function to open a specific modal
    const openModal = (modal) => {
        modal.classList.add("open");
        document.body.style.overflow = "hidden"; // Disable page scroll when modal is open
    };

    // Function to close a specific modal
    const closeModal = (modal) => {
        modal.classList.remove("open");
        document.body.style.overflow = "auto"; // Enable page scroll when modal is closed
    };

    // Get all open buttons
    const openButtons = document.querySelectorAll(".open-product-modal");

    const openLanguageModals = document.querySelectorAll(".open-language-modal");

    
    // Add click event to each open button
    openButtons.forEach(button => {
        button.addEventListener("click", function (e) {
            e.preventDefault();
         
            const modal = specModal;


            // Open the corresponding modal
            if (modal) {
                openModal(modal);
            }
        });
    });

    if (openLanguageModals && languageModal) {
        openLanguageModals.forEach(openLanguageModal => {
        openLanguageModal.addEventListener("click", function (e) {
            e.preventDefault();
            openModal(languageModal);
        });
    });
    }

    // Add click event to close buttons within all modals
    const closeButtons = document.querySelectorAll(".modal__close");
    closeButtons.forEach(button => {
        button.addEventListener("click", function () {
            // Find the closest modal to this button and close it
            const modal = this.closest(".modal");
            closeModal(modal);
        });
    });

    // Close modal when clicking outside of the modal content
    window.addEventListener("click", function (event) {
        if (event.target.classList.contains("modal")) {
            closeModal(event.target);
            return;
        }

        if (event.target.classList.contains("close-row")) {
            closeModal(event.target.closest(".modal"));
            return;
        }
    });
});
