document.addEventListener('DOMContentLoaded', function() {
    const mobileMenuActivator = document.querySelector('header button.mobile-menu-activator');

    if (mobileMenuActivator) {
        mobileMenuActivator.addEventListener('click', () => {
            mobileMenuActivator.classList.toggle('open');
            document.body.classList.toggle('mobile-menu-open');
        });
    }

    const mobileMenu = document.querySelector('div#main-mobile-menu');
    const mobileMenuItemsWithChildren = mobileMenu.querySelectorAll('.menu-item-has-children');
    const mobileMenuItemsWithChildrenLinks = mobileMenu.querySelectorAll('.menu-item-has-children > a');

    // When menu item with children is clicked, prevent default action and toggle the submenu via max-height
    mobileMenuItemsWithChildrenLinks.forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();

            let itemParent = item.parentElement;
            let itemSubmenu = itemParent.querySelector('.sub-menu');
            
            // Set the max-height of the submenu to its height if the style does not exist
            if (!itemSubmenu.style.maxHeight) {
                itemSubmenu.style.maxHeight = itemSubmenu.scrollHeight + 'px';
                itemParent.classList.toggle('open');
            } else {
                itemSubmenu.style.maxHeight = null;
                itemParent.classList.toggle('open');
            }

        });
    });

});


document.addEventListener('devkitLazyLoad-Wave', () => {
    // Initialize the canvas animation for all canvases with class 'waveCanvas'
    document.querySelectorAll('.mobileMenuWave').forEach(canvas => {
        new window.WaveCanvas(canvas, {
            widthOffset: 0,
            verticalOffset: -200,
            diagonalSlopeFactor: 0.7,
            horizontalOffset: 0,
            baseAmplitude: 60,
        });
    });
});

