document.addEventListener('devkitLazyLoad-Wave', () => {
    // Initialize the canvas animation for all canvases with class 'waveCanvas'
    document.querySelectorAll('.heroWave').forEach(canvas => {
        new window.WaveCanvas(canvas, {
            widthOffset: 0,
            verticalOffset: -450,
            diagonalSlopeFactor: 0.3
        });
    });
});

// document.addEventListener('DOMContentLoaded', function () {
//     const video = document.getElementById('myVideo');

//     // Check if the video exists
//     if (video) {
//         video.addEventListener('click', function () {
//             if (video.paused) {
//                 video.play(); // Play the video if it's paused
//             } else {
//                 video.pause(); // Pause the video if it's playing
//             }
//         });

//         video.addEventListener('ended', function () {
//             video.currentTime = 0; // Reset video to start when it ends
//         });
//     }
// });
