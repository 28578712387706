document.addEventListener('DOMContentLoaded', function () {
    // .sticky-contact-banner
    const contactBanner = document.querySelector('.sticky-contact-banner');
    if (!contactBanner) return;

    // if #requirements-form is in the viewport, add .is-visible to .sticky-contact-banner
    const requirementsForm = document.querySelector('#requirements-form form');
    if (!requirementsForm) return;

    const requirementsFormObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                contactBanner.classList.add('form-in-view');
            } else {
                contactBanner.classList.remove('form-in-view');
            }
        });
    });

    requirementsFormObserver.observe(requirementsForm);
});