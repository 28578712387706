document.addEventListener('devkitLazyLoad-Swiper', function() {
    const aboutHeroSlider = document.querySelectorAll('.about-hero-slider');

    if (!aboutHeroSlider) {
        return;
    }

    
    aboutHeroSlider.forEach((slider) => {

        const nextArrow = slider.querySelector('.swiper-button-next');
        const prevArrow = slider.querySelector('.swiper-button-prev');
        const slideNum = slider.querySelector('.slide-num .current');
        const bottomSection = slider.closest('.bottom');
        
        var activeIndex = 0;

        const swiper = new Swiper(slider, {
            loop: false,
            // 70px between slides
            spaceBetween: 70,
            slidesPerView: 1,
            centeredSlides: false,
            loopedSlides: 3,
            navigation: {
                nextEl: nextArrow,
                prevEl: prevArrow,
            },
            on: {
                init: function() {
                    this.update(); // Force Swiper to update its slides and clones
                },
                slideChange: function() {
                    slideNum.textContent = String(this.realIndex + 1).padStart(2, '0');

                    // if slide has data-image attribute, set it as background image
                    const slide = this.slides[this.realIndex];
                    const image = slide.getAttribute('data-image');
                    if (image) {
                        bottomSection.style.backgroundImage = `url(${image})`;
                    }
             
                }
            }
        });


        nextArrow.addEventListener('click', (e) => {
           if (activeIndex == swiper.slides.length - 1) {
            swiper.slideTo(0);
            activeIndex = 0;
            return;
           }
           activeIndex++;
        });

        prevArrow.addEventListener('click', (e) => {
            if (activeIndex == 0) {
                swiper.slideTo(swiper.slides.length - 1);
                activeIndex = swiper.slides.length - 1;
                return;
            }
            activeIndex--;
        });



      
    });

});