/**
 * Event listener for 'devkitLazyLoad-swiper' that initializes Swiper instances for review sliders.
 *
 * This function selects all elements with the class 'reviews-slider' and initializes a Swiper instance
 * for each element that contains at least two slides. 
 *
 * @event devkitLazyLoad-swiper
 */
document.addEventListener('devkitLazyLoad-Swiper', function () {

    const reviewSliders = document.querySelectorAll('.reviews-slider');

    reviewSliders.forEach(function (slider) {

        var looped = true

        // if less than 2 slides, don't initialize swiper
        if (slider.querySelectorAll('.swiper-slide').length <= 2) {
            looped = false
        }

        /**
         * Initializes a Swiper instance.
         *
         * @param {HTMLElement} slider - The DOM element that contains the Swiper slider.
         * @returns {Swiper} The initialized Swiper instance.
         *
         */
        const swiper = new Swiper(slider, {
            slidesPerView: 1,
            // Draggable
            draggable: true,
            loop: looped,
            navigation: {
                nextEl: slider.querySelector('.swiper-button-next'),
                prevEl: slider.querySelector('.swiper-button-prev'),
            },
            breakpoints: {
                992: {
                    slidesPerView: 2,
                },
            },
        });
    });

})