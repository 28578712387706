// Smooth Scrolling Script with Center Alignment
document.addEventListener('DOMContentLoaded', function () {
    // Function to smoothly scroll to a target element
    function smoothScroll(target) {
        const targetElement = document.querySelector(target);
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }

    // Handle click events on all anchor links with hash href
    const links = document.querySelectorAll('a[href^="#"]');
    links.forEach(function (link) {
        link.addEventListener('click', function (event) {
            const targetHash = this.getAttribute('href');
            if (targetHash.length > 1) {
                event.preventDefault();
                smoothScroll(targetHash);
                // Update the URL hash without jumping to it
                history.pushState(null, null, targetHash);
            }
        });
    });

    // Smoothly scroll to the element if the page loads with a hash
    if (window.location.hash) {
        smoothScroll(window.location.hash);
    }
});
