document.addEventListener('DOMContentLoaded', function() {
    const languagePicker = document.getElementById('langauge-picker-select');
    
    if (languagePicker) {
        languagePicker.addEventListener('change', function() {
            const selectedOption = this.options[this.selectedIndex];
            const url = selectedOption.value;
            
            if (url) {
                window.location.href = url;
            }
        });
    }
});