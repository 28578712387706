document.addEventListener('DOMContentLoaded', function() {
    var body = document.body;
    var lastScrollY = window.scrollY;
    var ticking = false;

    function onScroll() {
        if (lastScrollY !== window.scrollY) {
            if (window.scrollY > 50) {
                body.classList.add('scrolled');
            } else {
                body.classList.remove('scrolled');
            }
            lastScrollY = window.scrollY;
        }
        ticking = false;
    }

    function handleScroll() {
        if (!ticking) {
            requestAnimationFrame(onScroll);
            ticking = true;
        }
    }

    window.addEventListener('scroll', handleScroll, { passive: true });

    // if user refreshes the page while scrolled down
    if (window.scrollY > 50) {
        body.classList.add('scrolled');
    }
    
});
